import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  private timestamp = new Date().getTime();

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json?ts=${this.timestamp}`);
  }
}
